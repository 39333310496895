/**
 * Theme global.js
 */
var $ = jQuery.noConflict();
$(document).ready(function () {
    if (getCookie('notification-closed') !== 'true') {
        $('.notification').addClass('show')
        if($('header .notification').length){
            $('main').addClass('notification-on')
        }
    }

    $('.top-navigation .search a').click(function (e) {
        e.preventDefault();
        $('.search-container').slideToggle(1);
    });

    $('.notification-close').click(function () {
        setCookie('notification-closed', true, 7)
        $(this).closest('.notification').removeClass('show')
        $('main').removeClass('notification-on')
    })

    $(window).on("scroll", function () {
        if ($(window).scrollTop() > 80) {
            $('header').addClass('scroll');
        } else {
            $('header').removeClass('scroll');
        }
    });

    if ($('.col-right .shortcuts-content').length) {
        // -20 is padding for shortcuts element
        var browser = $(window).height() - 20;
        var header = $('#header').length ? $('#header').outerHeight() : 0
        var adminBar = $('#wpadminbar').length ? $('#wpadminbar').outerHeight() : 0
        var height = browser - header - adminBar

        if (height > $('.col-right .shortcuts-content').outerHeight()) {
            $('.col-right .shortcuts').addClass('sticky')
        }
    }

    $('.accordion .summary').on('click', function () {
        $(this).siblings('.details').slideToggle(300);
        $(this).children('i').toggleClass('open closed');
    })

    //Mobile menu
    $('.hamburger--stand').on('click', function (e) {
        $(this).toggleClass('is-active');
        $('#mobile-main-navigation').toggleClass('open');
    });
    $('.close svg').on('click', function (e) {
        $('.hamburger--stand').removeClass('is-active');
        $('#mobile-main-navigation').removeClass('open');
    })
    if ($('#mobile-main-navigation .menu > .menu-item-has-children').length) {
        $('#mobile-main-navigation .menu-item-has-children > a').append('<i class="angle-down"></i>');
    }

    // Ylätason valikko
    $('#mobile-main-navigation .menu > li > a > i').on('click', function (e) {
        e.preventDefault();

        let $icon = $(this);
        let $submenu = $icon.closest("li").children(".sub-menu");
        let $parentLi = $icon.closest("li");

        // Suljetaan kaikki muut ylätason sisarvalikot ja niiden lapset
        $parentLi.siblings().find(".sub-menu, i").removeClass("open");

        // Avataan tai suljetaan klikattu valikko
        $submenu.toggleClass("open");
        $icon.toggleClass("open");
    });

    // Alatason valikot
    $('#mobile-main-navigation .menu .sub-menu i').on('click', function (e) {
        e.preventDefault();

        let $icon = $(this);
        let $submenu = $icon.parent().siblings('.sub-menu');
        let $parentLi = $icon.closest("li");

        // Suljetaan saman tason muut sisarvalikot ja niiden lapset
        $parentLi.siblings().find(".sub-menu, i").removeClass("open");

        // Avataan tai suljetaan klikattu alavalikko
        $submenu.toggleClass("open");
        $icon.toggleClass("open");
    });

    // Avaa valikon, jos siinä on 'current_page_item' ja pidä ne auki
    $('#mobile-main-navigation .menu li.current_page_item').each(function () {
        let $li = $(this);
        $li.parents(".sub-menu").addClass("open");
        $li.parents("li").children("a").children("i").addClass("open");
    });

    // Sulje kaikki muut valikot paitsi current_page_itemin sisältämät
    $(".sub-menu").not(".open").removeClass("open");
    $(".menu a i").not(".open").removeClass("open");

    AOS.init();
})

function setCookie(key, value, expiry) {
    var expires = new Date();
    expires.setTime(expires.getTime() + (expiry * 24 * 60 * 60 * 1000));
    document.cookie = key + '=' + value + ';path=/;expires=' + expires.toUTCString();
}

function getCookie(key) {
    var keyValue = document.cookie.match('(^|;) ?' + key + '=([^;]*)(;|$)');
    return keyValue ? keyValue[2] : null;
}
